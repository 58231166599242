import { type DeepReadonly, defineComponent, type PropType } from "vue";
import { type ContentTypeProps, createStore, type ExtendedContentType, type FilterOption, type SearchStoreActions, type SearchStoreState, type SummariesPaging, type UpdateFilter } from "@/components/search/fullSearchStore";
import type { GaVueComponent } from "@/common/vueUtils";
import { useI18n } from "@/i18n/i18nSetup";
import "./fullSearch.scss";
import { GaChip } from "@/components/general/GaChip";
import { ActionableCardMini } from "@/components/actionable/ActionableCardMini";
import { SummaryCard } from "@/components/cards/SummaryCard";
import ChannelCardMini from "@utils/vue-migration/component/channel/ChannelCardMini.vue";
import CustomPageCardMini from "@utils/vue-migration/component/custompage/CustomPageCardMini.vue";
import type { ContentTypes } from "@/components/instant-search/_instant-search";
import type { FormFilter, FullSearchProps } from "@newgenerated/shared/schema";
import { LoadingComponent } from "@/components/search/LoadingComponent";
import { GaButton } from "@/components/general/GaButton";
import { Filter } from "@/components/search/Filter";
import { FilterChip } from "@/components/search/FilterChip";
import type { MultiSelectProps } from "@/components/form/GaFormFieldMultiSelect";
import { NoResults } from "@/components/search/NoResults";
import { assertIsDefined } from "@utils/assertion";
import { SearchAskGetAbstractComponent } from "@/components/search/SearchAskGetAbstractComponent";

function getFilterOptionByName(name: string, filterOptions: DeepReadonly<FilterOption[]>): FilterOption {
  const filterOption = filterOptions.find((value) => value.filterName === name);
  assertIsDefined(filterOption);
  return filterOption;
}

function Filters(props: {
  formFilters: DeepReadonly<FormFilter[]>;
  applyFilters: () => void;
  updateFilter: UpdateFilter;
  filtersExtended: boolean;
  msProps: DeepReadonly<MultiSelectProps<string>>;
  filterOptions: DeepReadonly<FilterOption[]>;
  toggleFilter: (filterName: string) => void;
}): GaVueComponent {
  const { t } = useI18n();
  return (
    <div class={["collapse", props.filtersExtended ? "show" : ""]}>
      <div>
        <div class="filtering-dropdown">
          <div class="filtering-dropdown__left-col">
            {props.formFilters
              .filter((filter) => filter.priority === "PRIMARY")
              .map((filter) => (
                <Filter filter={filter} updateFilter={props.updateFilter} msProps={props.msProps} filterOption={getFilterOptionByName(filter.name, props.filterOptions)} toggleFilter={props.toggleFilter} />
              ))}
          </div>
          <div class="filtering-dropdown__right-col">
            {props.formFilters
              .filter((filter) => filter.priority === "SECONDARY")
              .map((filter) => (
                <Filter filter={filter} updateFilter={props.updateFilter} msProps={props.msProps} filterOption={getFilterOptionByName(filter.name, props.filterOptions)} toggleFilter={props.toggleFilter} />
              ))}
          </div>
          <div>
            <GaButton onClick={props.applyFilters}>{t("general:button.apply")}</GaButton>
          </div>
        </div>
      </div>
    </div>
  );
}

function ContentTypeChildren(props: { contentType: DeepReadonly<ContentTypeProps>; formFilters: DeepReadonly<FormFilter[]>; summariesPaging: SummariesPaging; loadMoreSummaries: () => void; selected: boolean }): GaVueComponent {
  const category = props.contentType;
  const amountToShow = props.selected ? category.items.length : props.contentType.amountToShow;
  const standardColumns = "row row-cols-1 row-cols-md-2 g-4 ";
  const bigColumn = standardColumns + (props.selected ? "row-cols-lg-3" : "");
  const smallColumn = standardColumns + (props.selected ? "row-cols-lg-4" : "row-cols-lg-3");
  const { t } = useI18n();
  switch (category.discriminator) {
    case "SUMMARY":
      return (
        <>
          <div class={bigColumn}>
            {category.items.slice(0, amountToShow).map((result) => (
              <div class="col">
                <SummaryCard
                  summary={{
                    ...result,
                    authors: [...result.authors],
                    countriesIncluded: [...result.countriesIncluded],
                    countriesExcluded: [...result.countriesExcluded],
                  }}
                />
              </div>
            ))}
          </div>
          {props.summariesPaging.totalCount > category.items.length && props.selected ? (
            <div class="d-flex justify-content-center mt-3">
              <GaButton onClick={() => props.loadMoreSummaries()}>{t("browse:explore.loadMore", ["12"])}</GaButton>
            </div>
          ) : null}
        </>
      );
    case "CHANNEL":
      return (
        <div class={smallColumn}>
          {category.items.slice(0, amountToShow).map((result) => (
            <div class="col">
              <ChannelCardMini channel={result} />
            </div>
          ))}
        </div>
      );
    case "CUSTOMPAGE":
      return (
        <div class={smallColumn}>
          {category.items.slice(0, amountToShow).map((result) => (
            <div class="col">
              <CustomPageCardMini customPage={result} />
            </div>
          ))}
        </div>
      );
    case "ACTIONABLE":
      return (
        <div class={bigColumn}>
          {category.items.slice(0, amountToShow).map((result) => (
            <div class="col">
              <ActionableCardMini actionable={{ actionableId: BigInt(result.actionableId), name: result.name, title: result.title, coverUri: result.coverUri }} />
            </div>
          ))}
        </div>
      );
  }
}

function ContentType(props: {
  contentType: DeepReadonly<ContentTypeProps>;
  selectContentType: (contentType: ExtendedContentType) => void;
  loadMoreSummaries: () => void;
  loading: boolean;
  formFilters: DeepReadonly<FormFilter[]>;
  actions: SearchStoreActions;
  selectedContentType: ExtendedContentType;
  filtersExtended: boolean;
  msProps: DeepReadonly<MultiSelectProps<string>>;
  summariesPaging: SummariesPaging;
  filterOptions: DeepReadonly<FilterOption[]>;
  toggleFilter: (filterName: string) => void;
}): GaVueComponent {
  const { t } = useI18n();
  const isSelected = props.selectedContentType === props.contentType.discriminator;
  if (props.loading) {
    return <LoadingComponent large={props.contentType.discriminator === "SUMMARY"} />;
  } else if (props.contentType.items.length > 0 || isSelected) {
    return (
      <div>
        <h2 class="mb-4">{props.contentType.title}</h2>
        {props.contentType.discriminator === "SUMMARY" && isSelected ? (
          <div class="mb-4">
            <div class="d-flex flex-wrap gap-2 mb-3">
              <GaChip icon="ico-equalizer" type={"checkbox"} changeHandler={props.actions.toggleFilters}>
                {t("summary:filtersTitle")}
              </GaChip>
              {props.formFilters
                .filter((filter) => filter.activeValues.length > 0)
                .map((filter) => (
                  <FilterChip filter={filter} resetFilter={props.actions.resetFilter} />
                ))}
            </div>
            <Filters
              formFilters={props.formFilters}
              applyFilters={props.actions.search}
              updateFilter={props.actions.updateFilter}
              filtersExtended={props.filtersExtended}
              msProps={props.msProps}
              filterOptions={props.filterOptions}
              toggleFilter={props.toggleFilter}
            />
          </div>
        ) : null}
        <ContentTypeChildren contentType={props.contentType} formFilters={props.formFilters} loadMoreSummaries={props.loadMoreSummaries} summariesPaging={props.summariesPaging} selected={isSelected} />
        {props.selectedContentType === "ALL" ? (
          <div class="mt-3">
            <a type="button" class="btn btn-alt-primary btn-sm" onClick={() => props.selectContentType(props.contentType.discriminator)}>
              {t("search:page.results.showMore")}
            </a>
          </div>
        ) : null}
      </div>
    );
  }
  return <></>;
}

function SearchContentTabs(props: { selectContentType: (type: ExtendedContentType) => void; selectedContentType: ExtendedContentType; contentTypes: DeepReadonly<ContentTypeProps[]> }): GaVueComponent {
  const tabOrder: { [content in ContentTypes]: number } = {
    SUMMARY: 1,
    ACTIONABLE: 2,
    CHANNEL: 3,
    CUSTOMPAGE: 4,
  };
  const { t } = useI18n();
  return (
    <div class="d-flex gap-3 flex-wrap">
      <GaChip active={props.selectedContentType === "ALL"} changeHandler={() => props.selectContentType("ALL")} value="ALL" type="radio" name="contentTypeTabs">
        {t("search:page.results.topResults")}
      </GaChip>
      {[...props.contentTypes]
        .sort((a, b) => tabOrder[a.discriminator] - tabOrder[b.discriminator])
        .filter((contentType) => contentType.items.length > 0)
        .map((contentType) => (
          <span>
            <GaChip changeHandler={() => props.selectContentType(contentType.discriminator)} active={contentType.discriminator === props.selectedContentType} value={contentType.discriminator} type="radio" name="contentTypeTabs">
              {contentType.title}
            </GaChip>
          </span>
        ))}
    </div>
  );
}

function getBreakPointMdValue(): number {
  const breakPointValue = getComputedStyle(document.body).getPropertyValue("--ga-breakpoint-lg");
  return parseInt(breakPointValue, 10);
}

function Content(props: { state: DeepReadonly<SearchStoreState>; actions: SearchStoreActions }): GaVueComponent {
  const { t } = useI18n();
  const msProps: DeepReadonly<MultiSelectProps<string>> = {
    ...props.state.multiSelectProps,
    onSearchTermChange: props.actions.onSearchTermChange,
    onChange: props.actions.onChange,
    toggleSearch: props.actions.toggleSearch,
  };
  const filteredContentTypes = props.state.contentTypeProps.filter((value) => props.state.selectedContentType === "ALL" || props.state.selectedContentType === value.discriminator);
  const smallVersion = getBreakPointMdValue() >= window.innerWidth;

  return (
    <div class="row mt-4">
      <h1 class="mb-4">{t("search:page.title")}</h1>
      <div class="mb-5">
        <SearchContentTabs selectContentType={props.actions.selectContentType} selectedContentType={props.state.selectedContentType} contentTypes={props.state.contentTypeProps} />
      </div>
      <div class={["col-12 order-1 order-lg-0", props.state.selectedContentType === "ALL" ? "col-lg-8" : ""]}>
        {filteredContentTypes.some((ct) => ct.items.length > 0) || props.state.loading ? (
          <>
            {filteredContentTypes.map((contentType) => (
              <div class="mb-4">
                <ContentType
                  selectContentType={props.actions.selectContentType}
                  contentType={contentType}
                  loading={props.state.loading}
                  formFilters={props.state.formFilters}
                  selectedContentType={props.state.selectedContentType}
                  actions={props.actions}
                  filtersExtended={props.state.filtersExtended}
                  msProps={msProps}
                  loadMoreSummaries={props.actions.loadMoreSummaries}
                  summariesPaging={props.state.summariesPaging}
                  filterOptions={props.state.filterOptions}
                  toggleFilter={props.actions.toggleSingleFilter}
                />
              </div>
            ))}
          </>
        ) : (
          <div class="py-3">
            <NoResults resetFilters={props.actions.resetAllFilters} />
          </div>
        )}
      </div>
      <div class={["col-12 order-0 order-lg-1 mb-5", props.state.selectedContentType === "ALL" ? "col-lg-4" : "d-none"]}>
        <h2 class="mb-4">{t("search:page.askGetabstract.title")}</h2>
        <SearchAskGetAbstractComponent state={props.state.aiState.uiState} displayFullAnswer={props.state.aiState.displayFullAnswer} showMore={props.actions.showFullAiAnswer} smallVersion={smallVersion} />
      </div>
    </div>
  );
}

export const FullSearch = defineComponent({
  props: {
    fullSearchProps: {
      type: Object as PropType<FullSearchProps>,
      required: true,
    },
  },
  setup: (props) => {
    const store = createStore(props.fullSearchProps);
    return () => <Content state={store.state()} actions={store.actions} />;
  },
});
